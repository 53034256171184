import {Modal} from "bootstrap";

export default function (title, description, options) {
    const defaultOptions = {
        yesLabel: 'Да',
        cancelLabel: 'Отмена',
        okLabel: 'OK',
        callback: null,
        type: "confirm",
        centered: false,
        closeButton: true
    }

    options = {...defaultOptions, ...options};

    let modalWrap = document.getElementById("modal_dialog");

    if (modalWrap) {
        modalWrap.remove();
    }

    const backDrop = document.querySelector('.modal-backdrop');
    if (backDrop) {
        backDrop.remove();
    }

    modalWrap = document.createElement('div');
    modalWrap.id = "modal_dialog";

    let dialogClass = 'modal-dialog';

    if (options.centered) {
        dialogClass += ' modal-dialog-centered';
    }

    let closeButton = '';

    if (options.closeButton) {
        closeButton = '<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>';
    }

    if (options.type === 'confirm') {
        let cancelButton = '';
        if (options.cancelLabel !== false) {
            cancelButton = '<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">'+options.cancelLabel+'</button>';
        }

        modalWrap.innerHTML = `
<div class="modal fade" tabindex="-1" data-bs-backdrop="static">
  <div class="${dialogClass}">
    <div class="modal-content">
      <div class="modal-header bg-light">
        <h5 class="modal-title">${title}</h5>
        ${closeButton}
      </div>
      <div class="modal-body">
        <p>${description}</p>
      </div>
      <div class="modal-footer bg-light">
        ${cancelButton}
        <button type="button" class="btn btn-primary modal-success-btn" data-bs-dismiss="modal">${options.yesLabel}</button>
      </div>
    </div>
  </div>
</div>
`;
    } else if (options.type === 'alert') {
        modalWrap.innerHTML = 
`<div class="modal fade" tabindex="-1" data-bs-backdrop="static">
  <div class="${dialogClass}">
    <div class="modal-content">
      <div class="modal-header bg-light">
        <h5 class="modal-title">${title}</h5>
        ${closeButton}
      </div>
      <div class="modal-body">
        <p>${description}</p>
      </div>
      <div class="modal-footer bg-light">
        <button type="button" class="btn btn-primary modal-success-btn" data-bs-dismiss="modal">${options.okLabel}</button>
      </div>
    </div>
  </div>
</div>        
`
    } else if (options.type === 'confirm_with_comment') {
        modalWrap.innerHTML =
`<div class="modal fade" tabindex="-1" data-bs-backdrop="static">
  <div class="${dialogClass}">
    <div class="modal-content">
      <div class="modal-header bg-light">
        <h5 class="modal-title">${title}</h5>
        ${closeButton}
      </div>
      <div class="modal-body">
        <p>${description}</p>
        <p>Комментарий:</p>
        <textarea class="form-control modal-dialog-comment"></textarea>
      </div>
      <div class="modal-footer bg-light">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">${options.cancelLabel}</button>
        <button type="button" class="btn btn-primary modal-success-btn" data-bs-dismiss="modal">${options.yesLabel}</button>
      </div>
    </div>
  </div>
</div>`
    }

    if (options.callback instanceof Function) {
        modalWrap.querySelector('.modal-success-btn').onclick = function () {
            if (options.type === 'confirm_with_comment') {
                options.callback(modalWrap.querySelector('.modal-dialog-comment').value);
            } else {
                options.callback();
            }
        };
    }

    document.body.append(modalWrap);

    return new Modal(modalWrap.querySelector('.modal'));
}