import {Controller} from "@hotwired/stimulus";
import {handleCellDropdowns} from "../../../components/luckysheet/dropdownhandler";
import {cellRightClickConfig} from "../../../components/luckysheet/defaultconfig";

export default class extends Controller {
    luckysheet;

    static values = {
        data: String,
        projects: String,
        services: String
    }

    static get targets() {
        return [
            'table'
        ]
    }

    connect() {
        handleCellDropdowns();

        const options = {
            container: this.tableTarget.id,
            data: JSON.parse(this.dataValue),
            showtoolbar: false,
            showinfobar: false,
            showstatisticBar: false,
            sheetFormulaBar: false,
            lang: 'ru',
            showsheetbar: false,
            enableAddRow: false,
            cellRightClickConfig: cellRightClickConfig
        }

        luckysheet.create(options);

        luckysheet.setDataVerification({
            type: 'dropdown',
            value1: this.projectsValue,
        }, {
            range: 'A2:A50'
        });

        luckysheet.setDataVerification({
            type: 'dropdown',
            value1: this.servicesValue,
        }, {
            range: 'B2:B50'
        });

        this.luckysheet = luckysheet;
    }

    disconnect() {
        this.luckysheet.destroy();
    }

    async save() {
        const data = this.luckysheet.getAllSheets()[0]['data'];

        await fetch('', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                data: data
            })
        });

        window.location.reload();
    }
}